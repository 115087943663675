import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import "@fontsource/red-hat-display";

const Problem = () => {
  return (
    <Container className="problem_bg" maxWidth="lg" marginBottom={4}>
      <Grid
        container
        className="problem_text"
        rowSpacing={5}
        padding={{ xs: "2em", sm: "5em" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" sx={{ color: "white" }} fontWeight="bold">
            The problem
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Clinics and hospitals face significant challenges in finding the
            right tools to digitize their microscopes and slit lamps. Existing
            solutions are{" "}
            <b style={{ color: "#FFA100" }}>
              expensive, cumbersome to manage, and may require adherence to
              specific brands
            </b>
            , making it <b>difficult to integrate</b> into diverse healthcare
            settings.
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} sm={3} marginTop={{ sm: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{ color: "#8F4FFF" }}
              fontWeight="bold"
            >
              4 of 5
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <Typography variant="h6" sx={{ color: "white" }}>
              people who are blind may not be. The reason is lack of health
              access.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={3} marginTop={{ sm: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{ color: "#8F4FFF" }}
              fontWeight="bold"
            >
              1 B
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <Typography variant="h6" sx={{ color: "white" }}>
              people are visually impared
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={3} marginTop={{ sm: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{ color: "#8F4FFF" }}
              fontWeight="bold"
            >
              3.7
            </Typography>
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <Typography variant="h6" sx={{ color: "white" }}>
              of ophthalmologists per million people in low-income countries,
              and 76.2 in high-income countries
            </Typography>
          </Grid> */}
        {/* </Grid> */}
      </Grid>
    </Container>
  );
};

export default Problem;
