import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Mission = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "mission_imgGrid" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Container maxWidth={"lg"}>
      <Grid container justifyContent="center" marginBottom={{ xs: 6, sm: 10 }}>
        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 2, sm: 1 }}
          sx={{
            background: "rgba(253, 253, 253, 0.15)",
          }}
        >
          <GatsbyImage
            image={getImage(data.file.childImageSharp)}
            key={data.file.name}
            fadeIn={false}
            loading="eager"
            style={{ borderRadius: "15px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          className="mission_text"
          padding={3}
          marginLeft={{ xs: 0, sm: 1 }}
          marginBottom={{ xs: 1, sm: 0 }}
          order={{ xs: 1, sm: 2 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h2"
            color={"white"}
            paddingTop={2}
            marginTop={{ sm: 4 }}
            fontWeight="bold"
          >
            Mission
          </Typography>
          <Typography variant="h5" color={"white"} paddingTop={2}>
            Custom Surgical empowers <b>eye care professionals</b> with{" "}
            <b style={{ color: "#FFA100" }}>
              advanced digitalization and AI tools
            </b>
            , enabling faster, and accessible diagnoses to{" "}
            <b>improve global eye health.</b>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Mission;
