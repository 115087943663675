import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "Slit lamp with MicroREC transparent_(1)" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <Box className="solution_svg" marginBottom={{ xs: 6, sm: 10 }}>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems={{ xs: "flex-start", lg: "center" }}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={5}>
            <GatsbyImage
              style={{ transform: "scaleX(-1)" }}
              image={getImage(data.file.childImageSharp)}
              key={data.file.name}
              fadeIn={false}
              loading="eager"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h2" paddingTop={3} fontWeight="bold">
              Our solution
            </Typography>
            <Typography variant="h5" paddingTop={3}>
              Custom Surgical offers a{" "}
              <b>versatile, brand-agnostic solutions</b> to digitise surgical
              microscopes and slit-lamps, and{" "}
              <b>
                securely manage your clinical images and videos in the cloud.
              </b>
            </Typography>
            <Typography variant="h5" paddingTop={3}>
              Our diverse product line-up integrates smoothly into any clinical
              environment, prioritizing{" "}
              <b>
                user-friendliness, cost-effectiveness, and operational
                efficiency.
              </b>
            </Typography>
            <Typography variant="h5" paddingTop={3}>
              With our cutting-edge solutions, healthcare professionals can
              concentrate on their primary goal—
              <b>providing outstanding patient care.</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Solution;
