import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import logo from "/src/images/iso_color.png";
import Problem from "../components/about/problem";
import Solution from "../components/about/solution";
import Mission from "../components/about/mission";
import History from "../components/about/history";
import Team from "../components/about/team";
import Map from "../components/about/map";
import Awards from "../components/about/awards";
import { Grid } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Subscribe from "../components/misc/check_list";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="About | Custom Surgical" />

        <Subscribe />
        <Grid container xs={12} className="about_main">
          <h1 className="text_over_about">
            The{" "}
            <span style={{ color: "#FFA100", marginLeft: "5px" }}>
              {" "}
              One-Stop-Shop{" "}
            </span>
            <div style={{ marginTop: "1rem" }}>for Digitalization</div>
          </h1>
        </Grid>

        <Problem />
        <Solution />
        <Mission />
        <Map />

        {/* <div style={{ marginBottom: '5%' }}></div> */}
        {/* <History /> */}
        {/* <div style={{ marginBottom: '5%' }}></div> */}

        <Awards />
        <Team />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
